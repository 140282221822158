import { useEffect } from 'react';
import classNames from 'classnames';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useForm, SubmitHandler, useFieldArray } from 'react-hook-form';

import {
  AssessmentAnswer,
  CompleteAssessmentAnswerInput,
  useProviderCompleteFormMutation,
  useProviderProgramAssessmentQuery,
} from '../../../generated/graphql';

import {
  isInitialsAssessmentQuestion,
  isSignatureAssessmentQuestion,
  isStatementAssessmentQuestion,
} from '../../lib/assessments';
import {
  mapAssessmentAnswersToMutationInput,
  ProgramAssessmentQuestion,
} from '../../lib/program-assessment';
import { getTimeStamp } from '../../lib/time';
import { getColorForSignatureUserType } from '../../lib/multiSignature';

import { useAuth } from '../../../contexts/AuthContext';

import Spinner from '../../svgs/Spinner';
import SignatureFlagName from '../../svgs/SignatureFlagName';
import SignatureFlagRole from '../../svgs/SignatureFlagRole';

import Button from '../../components/Button';
import Avatar from '../../components/Avatar';
import Checkbox from '../../components/Checkbox';
import InputGroup from '../../components/InputGroup';
import CompletedSignature from '../../components/CompletedSignature';
import { customToast } from '../../components/ToastAlert/customToast';

import {
  CompleteProviderFormData,
  isIncompleteClientSignatureQuestion,
} from './helpers';
import Question from './Question';
import SignatureDisplay from './SignatureDisplay';

const CompleteProviderForm = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { authedProviderUser } = useAuth();
  const location = useLocation();

  const locationState = location.state as {
    remainingProgramAssessmentIdsNeedingSignature?: string[];
    prefilledNameAndRole?: {
      prefilledName: string;
      prefilledRole: string;
    };
  };

  const programAssessmentIdsNeedingSignature =
    locationState?.remainingProgramAssessmentIdsNeedingSignature;

  const prefilledName = locationState?.prefilledNameAndRole?.prefilledName;
  const prefilledRole = locationState?.prefilledNameAndRole?.prefilledRole;

  const programAssessmentId = params.programAssessmentId || '';

  const {
    data,
    loading,
    error,
    refetch: refetchProgramAssessment,
  } = useProviderProgramAssessmentQuery({
    variables: { programAssessmentId: programAssessmentId! },
    skip: !programAssessmentId,
    onCompleted: (data) => {
      if (!data || error) {
        navigate('/');
      }
    },
  });

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
    formState,
  } = useForm<CompleteProviderFormData>({
    defaultValues: {
      providerSignatureName: '',
      providerSignatureRole: '',
      signatureTermsAgreed: false,
    },
  });

  useFieldArray({
    control,
    name: 'answers',
    rules: { required: false },
  });

  const watchSignatureName = watch('providerSignatureName');
  const watchSignatureRole = watch('providerSignatureRole');
  const watchSignatureTermsAgreed = watch('signatureTermsAgreed');

  const programAssessment = data?.programAssessment;
  const programAssessmentSignatureUsers =
    programAssessment?.programAssessmentSignatureUsers;

  const currentSigner = programAssessment?.currentSigner;

  const providerIsNotCurrentSigner =
    currentSigner &&
    authedProviderUser &&
    currentSigner?.providerUser?.id !== authedProviderUser?.id;

  const formIsReadOnly = providerIsNotCurrentSigner || !currentSigner;

  useEffect(() => {
    if (prefilledName) {
      setValue('providerSignatureName', prefilledName);
    }
    if (prefilledRole) {
      setValue('providerSignatureRole', prefilledRole);
    }
  }, [programAssessmentId]);

  useEffect(() => {
    if (providerIsNotCurrentSigner) {
      const userHasAlreadySigned = programAssessmentSignatureUsers?.some(
        (user) =>
          user.providerUser?.id === authedProviderUser?.id && user.signedAt,
      );

      if (userHasAlreadySigned) {
        customToast.success(`You have already completed this form.`);
      } else {
        customToast.warning(`It's not your turn to complete this form.`);
      }
    }
  }, [providerIsNotCurrentSigner]);

  const questions = programAssessment?.assessment
    ?.questions as ProgramAssessmentQuestion[];
  const answers = programAssessment?.answers as AssessmentAnswer[];

  const [providerCompleteForm] = useProviderCompleteFormMutation();

  const nowFormattedTimestamp = getTimeStamp(
    new Date().toISOString(),
    true,
    false,
  );

  const onSubmit: SubmitHandler<CompleteProviderFormData> = async (
    formData,
  ) => {
    const assessmentAnswers: CompleteAssessmentAnswerInput[] =
      mapAssessmentAnswersToMutationInput(questions, formData.answers);

    try {
      await providerCompleteForm({
        variables: {
          input: {
            programAssessmentId,
            signatureName: formData.providerSignatureName,
            signatureRole: formData.providerSignatureRole,
            assessmentAnswers,
          },
        },
      });

      customToast.success(
        `Successfully completed ${programAssessment?.assessment.name}!`,
      );

      if (
        programAssessmentIdsNeedingSignature &&
        programAssessmentIdsNeedingSignature.length > 0
      ) {
        const [
          firstProgramAssessmentId,
          ...remainingProgramAssessmentIdsNeedingSignature
        ] = programAssessmentIdsNeedingSignature;

        navigate(`/provider-form/${firstProgramAssessmentId}`, {
          state: {
            ...(remainingProgramAssessmentIdsNeedingSignature.length > 0 && {
              remainingProgramAssessmentIdsNeedingSignature,
            }),
            prefilledNameAndRole: {
              prefilledName: formData.providerSignatureName,
              prefilledRole: formData.providerSignatureRole,
            },
          },
        });
      } else {
        navigate('/');
      }

      // Handle success (e.g., redirect or show a success message)
    } catch (err) {
      console.error('Errors submitting:', err);
      customToast.error(
        `Something went wrong signing ${programAssessment?.assessment.name}. Please try again.`,
      );
      await refetchProgramAssessment();
      // Handle error (e.g., show an error message)
    } finally {
      reset();
    }
  };

  if (loading) {
    return (
      <div className="mt-20 flex h-full w-full flex-row items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex h-[100vh-64px] text-neutral-125">
      {!formIsReadOnly && (
        <div
          className={classNames(
            'sticky top-16 h-full w-1/3 min-w-[550px] p-6 pl-20',
            'transition-all duration-150 ease-in-out',
          )}
        >
          {/* Left side: Fixed SignatureUserInput */}
          <div className="mb-4 flex flex-row items-center">
            <Avatar
              size="medium"
              name={programAssessment.patientNameData?.name}
              imageUrl={
                programAssessment.patientNameData?.profileImageMedia?.url
              }
              className="mr-2.5"
            />
            <div className="text-body font-medium">
              {programAssessment.patientNameData?.name}
            </div>
          </div>
          <div className="mb-3 font-serif text-subtitle-small font-light">
            {programAssessment?.assessment.name}
          </div>
          <div className="mb-4 text-caption">
            It's your turn to complete the form. Your signature will be
            automatically added to all designated fields. You only need to sign
            once.
          </div>
          <div className="flex w-full items-center justify-between rounded-xl border border-neutral-50">
            <div
              className={classNames(
                'flex w-full flex-col items-start justify-start rounded-l-xl border-l-[14px] p-4',
                getColorForSignatureUserType(
                  programAssessment?.currentSigner?.assessmentSignatureUser
                    ?.signatureUserType,
                ).borderColor,
              )}
            >
              <SignatureDisplay
                programAssessmentSignatureUser={currentSigner}
              />

              <div className="mb-3 mt-2 text-caption font-medium text-green-150">
                Add your name and role here to sign electronically
              </div>

              <InputGroup
                placeholder="Name"
                IconLeft={SignatureFlagName}
                containerClassName="mb-3 w-[398px]"
                className="rounded-lg pl-12"
                {...register('providerSignatureName', {
                  required: true,
                  validate: (value) => {
                    const isValid = value === currentSigner?.providerUser?.name;
                    if (!isValid) {
                      setValue('signatureTermsAgreed', false);
                    }
                    return (
                      isValid ||
                      'Please enter your full name as displayed above'
                    );
                  },
                })}
                errorMessage={errors.providerSignatureName?.message}
              />
              <InputGroup
                placeholder="Role"
                IconLeft={SignatureFlagRole}
                containerClassName="mb-4 w-[398px]"
                className="rounded-lg pl-12"
                {...register('providerSignatureRole', { required: true })}
              />
              {watchSignatureName && (
                <CompletedSignature
                  isCurrentSigner
                  programAssessmentSignatureUser={currentSigner}
                  signatureName={watchSignatureName}
                  signatureRole={watchSignatureRole}
                  hideNameAndRole={!watchSignatureName || !watchSignatureRole}
                  hideDate={!watchSignatureName || !watchSignatureRole}
                  nowFormattedTimestamp={nowFormattedTimestamp}
                  className="mt-2 mb-3"
                />
              )}
              <Checkbox
                id="signatureTermsAgreed"
                className="mt-3"
                labelContent={
                  <div className="ml-2 text-caption">
                    I agree to the electronic signature{' '}
                    <a
                      href="https://www.homecoming.health/terms-of-service"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-green-100"
                    >
                      terms of service
                    </a>
                    .
                  </div>
                }
                {...register('signatureTermsAgreed', { required: true })}
              />
            </div>
          </div>
          <div className="mt-4 mb-6 text-caption">
            Once you sign your part, others will be notified to fill out their
            portion of the form.
          </div>
          <div className="flex flex-row items-center justify-end">
            <Button
              title="Cancel"
              theme="secondary"
              size="small"
              className="mr-3"
              onClick={() => navigate('/')}
            />
            <Button
              title={
                Boolean(programAssessmentIdsNeedingSignature?.length) ||
                currentSigner.orderIndex !== 0
                  ? 'Complete form'
                  : 'Complete & send form'
              }
              size="small"
              onClick={handleSubmit(onSubmit)}
              disabled={!watchSignatureTermsAgreed}
            />
          </div>
        </div>
      )}
      {/* Right side: Existing form content */}
      <div
        className={classNames('h-full overflow-y-auto p-6', {
          'flex w-full flex-col items-center': formIsReadOnly,
          'w-2/3': !formIsReadOnly,
        })}
      >
        <div className="flex w-full flex-col justify-start lg:w-full lg:max-w-[800px]">
          <div className="grid gap-y-6">
            {questions?.map((question, idx) => {
              const isStatementQuestion =
                isStatementAssessmentQuestion(question);

              const isReadOnlyQuestion = isIncompleteClientSignatureQuestion(
                question,
                answers?.[idx],
              );

              const isInitialsQuestion = isInitialsAssessmentQuestion(question);
              const isSignatureQuestion =
                isSignatureAssessmentQuestion(question);

              const hasError = Boolean(formState.errors?.answers?.[idx]);

              return (
                <div
                  key={`${idx}_${question.question}`}
                  className={classNames({
                    'w-full rounded-lg border border-neutral-75 p-4':
                      !isStatementQuestion,
                    'group relative transition-all duration-300 ease-in-out':
                      isReadOnlyQuestion,
                  })}
                >
                  {programAssessment?.assessment.questionFrame && (
                    <div className="mb-2 text-caption text-neutral-125">
                      {programAssessment?.assessment.questionFrame}
                    </div>
                  )}
                  <Question
                    key={`${idx}_${question.question}`}
                    question={question as ProgramAssessmentQuestion}
                    answer={answers?.[idx]}
                    idx={idx}
                    register={register}
                    control={control}
                    programAssessmentSignatureUsers={
                      programAssessmentSignatureUsers
                    }
                    currentSigner={currentSigner}
                    signatureName={watchSignatureName}
                    signatureRole={watchSignatureRole}
                    signatureTermsAgreed={watchSignatureTermsAgreed}
                    nowFormattedTimestamp={nowFormattedTimestamp}
                    formIsReadOnly={formIsReadOnly}
                  />
                  {hasError && (
                    <div className="mt-3 text-body text-red-100">
                      {formState.errors.answers?.[idx]?.value?.message}
                      {formState.errors.answers?.[idx]?.values?.message}
                    </div>
                  )}
                  {isReadOnlyQuestion && (
                    <div
                      className={classNames(
                        'absolute inset-0 flex flex-row items-center justify-center rounded-lg bg-white bg-opacity-90',
                        'opacity-0 transition-opacity duration-300 ease-in-out group-hover:opacity-100',
                      )}
                    >
                      <Avatar
                        size="medium"
                        name={programAssessment.patientNameData?.name}
                        imageUrl={
                          programAssessment.patientNameData?.profileImageMedia
                            ?.url
                        }
                        className="mr-2.5"
                      />
                      <div className="text-body font-medium text-neutral-125">
                        {programAssessment.patientNameData?.name} will sign
                        their {isInitialsQuestion ? 'initials' : ''}
                        {isSignatureQuestion ? 'full name' : ''} here
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompleteProviderForm;
