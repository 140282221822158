import { Fragment } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import {
  AdjustmentsIcon,
  CalendarIcon,
  LinkIcon,
} from '@heroicons/react/outline';

import { CALENDLY_SCHEDULED_EVENTS_URL } from '../../../lib/scheduled-events';

import IconButton from '../../../components/IconButton';
import MenuButton from '../../../components/MenuButton';

const CalendarSettingsMenu: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="z-[35] flex">
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button as={Fragment}>
          <IconButton
            aria-label="Calendar settings"
            IconComponent={AdjustmentsIcon}
            iconClassName={classNames('text-green-150 h-6 w-6 rotate-90')}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-30 mt-2 w-64 origin-top-right divide-y divide-neutral-50 rounded-md bg-white shadow-lg focus:outline-none">
            <MenuButton
              titleText="Manage event types"
              className="rounded-t-md"
              iconClassName="text-green-150"
              IconComponent={CalendarIcon}
              onClick={() =>
                window.open(CALENDLY_SCHEDULED_EVENTS_URL, '_blank')
              }
            />
            <MenuButton
              titleText="Calendly integration settings"
              className="rounded-b-md"
              iconClassName="text-green-150 rotate-90"
              IconComponent={LinkIcon}
              onClick={() => navigate('/integrations/calendly')}
            />
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default CalendarSettingsMenu;
