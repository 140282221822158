import { FC, useCallback, useEffect, useRef, useState } from 'react';

import toast from 'react-hot-toast';
import {
  useForm,
  SubmitHandler,
  SubmitErrorHandler,
  Controller,
  UseFieldArrayPrepend,
} from 'react-hook-form';
import { $convertToMarkdownString, TRANSFORMERS } from '@lexical/markdown';
import { Transition as HistoryTransition } from 'history';

import {
  ActivityUseType,
  ProgramActivityDataFragment,
  useAddActivityTextMutation,
  useEditActivityTextMutation,
} from '../../../../generated/graphql';

import { MODAL_TRANSITION_DURATION } from '../../Modal';

import ToastAlert from '../../ToastAlert';
import InputGroup from '../../InputGroup';
import Spinner from '../../../svgs/Spinner';
import { RefetchProviderActivities } from '../../../types/activity';
import { LexicalEditor } from 'lexical';
import Editor from '../../Editor';
import UnsavedChangesModal from '../UnsavedChangesModal';
import useBlocker from '../../../hooks/useBlocker';
import ModalDialog from '../../ModalDialog';
import { FollowUpForm } from '../../../lib/followUp';
import { formatProviderActivityForFollowUp } from '../../../lib/providerActivity';

type AddOrEditTextModalProps = {
  isModalOpen: boolean;
  setClosed: () => void;
  clearModalData?: () => void;
  refetchProviderActivities?: RefetchProviderActivities;
  followUpMode?: boolean;
  prependToFollowUp?: UseFieldArrayPrepend<FollowUpForm, 'activities'>;
  // This component needs to be compatible against Program-agnostic Activity entities in the Library
  // As well as Program-related ProgramActivity->ActivityText entities specific to a Client.
  // If selectedActivityText exists, operates in edit mode.
  selectedActivityText?: ProgramActivityDataFragment['activityText'];
  viewOnly?: boolean;
  onAdded?: (id: string) => void;
};

interface TextFormData {
  title: string;
  text: string;
}

const AddOrEditTextModal: FC<AddOrEditTextModalProps> = ({
  isModalOpen,
  setClosed,
  clearModalData,
  refetchProviderActivities,
  followUpMode = false,
  prependToFollowUp,
  selectedActivityText,
  viewOnly = false,
  onAdded,
}) => {
  const selectedActivityTextId = selectedActivityText?.id;
  const selectedTitle = selectedActivityText?.title;
  const selectedText = selectedActivityText?.text;

  const isExistingActivityText = Boolean(selectedActivityTextId);

  const [isSubmitting, setSubmitting] = useState(false);
  const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] =
    useState(false);
  const [navTransition, setNavTransition] = useState<HistoryTransition>();

  const [addActivityTextMutation, { loading: addActivityTextMutationLoading }] =
    useAddActivityTextMutation();
  const [
    editActivityTextMutation,
    { loading: editActivityTextMutationLoading },
  ] = useEditActivityTextMutation();

  const editorRef = useRef<LexicalEditor | null>(null);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { isDirty, errors: validationErrors },
    setFocus,
  } = useForm<TextFormData>({
    mode: 'all',
    defaultValues: { title: '', text: '' },
    values: { title: selectedTitle ?? '', text: selectedText ?? '' },
  });

  const watchTitle = watch('title');
  const watchText = watch('text');

  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => {
        setFocus('title');
      }, 50);
    }
  }, [isModalOpen]);

  const closeAndResetAddOrEditTextModal = async () => {
    setClosed();
    setTimeout(() => {
      clearModalData?.();
      setSubmitting(false);
      // Clears isDirty, important to reset for UnsavedChangesModal
      reset();
    }, MODAL_TRANSITION_DURATION);
  };

  const onSubmit: SubmitHandler<TextFormData> = async (
    formData: TextFormData,
  ) => {
    setSubmitting(true);

    const title = formData.title.trim();
    const text = formData.text; // Allow surrounding whitespace in document text

    try {
      if (isExistingActivityText && selectedActivityTextId) {
        const { data } = await editActivityTextMutation({
          variables: {
            input: {
              activityTextId: selectedActivityTextId,
              title,
              text,
            },
          },
        });

        if (data?.editActivityText) {
          refetchProviderActivities?.();
          closeAndResetAddOrEditTextModal();
          toast.custom(({ visible }) => (
            <ToastAlert
              isVisible={visible}
              level="success"
              message={`Successfully edited ${data.editActivityText.title}`}
            />
          ));
        }
      } else {
        const addActivityTextInput = {
          title,
          text,
          activityUseType: followUpMode
            ? ActivityUseType.OneTimeUse
            : ActivityUseType.Reusable,
        };

        const { data } = await addActivityTextMutation({
          variables: {
            input: addActivityTextInput,
          },
        });

        if (data?.addActivityText) {
          const newActivityText = data.addActivityText;
          refetchProviderActivities?.();
          closeAndResetAddOrEditTextModal();
          toast.custom(({ visible }) => (
            <ToastAlert
              isVisible={visible}
              level="success"
              message={`Successfully added ${newActivityText.title} to your ${
                followUpMode ? 'follow-up' : 'library'
              }`}
            />
          ));

          if (followUpMode && prependToFollowUp) {
            prependToFollowUp(
              formatProviderActivityForFollowUp(newActivityText),
            );
          }

          onAdded?.(data?.addActivityText?.id);
        }
      }
    } catch (err) {
      closeAndResetAddOrEditTextModal();
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Something went wrong."
          level="error"
        />
      ));
    } finally {
      setSubmitting(false);
    }
  };

  const handleErrors: SubmitErrorHandler<TextFormData> = (errors) => {
    console.error('Errors submitting:', errors);
  };

  const blocker = useCallback(
    (navTransition: HistoryTransition) => {
      if (!isUnsavedChangesModalOpen) {
        setNavTransition(navTransition);
        setIsUnsavedChangesModalOpen(true);
      }
    },
    [isDirty],
  );

  useBlocker(blocker, isDirty);

  return (
    <ModalDialog
      isOpen={isModalOpen}
      bodyClassName="pb-0 pt-0"
      width="medium"
      title={
        viewOnly
          ? watchTitle
          : `${isExistingActivityText ? 'Edit' : 'Create'} a text document`
      }
      setClosed={() => {
        if (isDirty) {
          setNavTransition(undefined);
          setIsUnsavedChangesModalOpen(true);
        } else {
          closeAndResetAddOrEditTextModal();
        }
      }}
      {...(!viewOnly
        ? {
            primaryActionTitle: isExistingActivityText
              ? !isSubmitting
                ? 'Save changes'
                : 'Saving...'
              : !isSubmitting
              ? `Add to ${followUpMode ? 'follow-up' : 'library'}`
              : 'Adding...',
            primaryActionOnClick: handleSubmit(onSubmit, handleErrors),
            primaryActionProps: isSubmitting
              ? {
                  IconComponent: Spinner,
                  iconClassName: 'h-[16px] w-[16px]',
                }
              : {},
            primaryActionDisabled: Boolean(
              addActivityTextMutationLoading ||
                editActivityTextMutationLoading ||
                isSubmitting ||
                watchTitle.trim() === '' ||
                watchText.trim() === '' ||
                validationErrors.title ||
                validationErrors.text,
            ),
          }
        : {
            titleClassName: 'text-big-label font-serif font-normal pt-3',
            headerClassName: 'border-none',
            hideFooter: true,
          })}
    >
      <div className="flex w-full flex-col">
        {!viewOnly && (
          <InputGroup
            autoFocus={true}
            required
            labelHidden
            backgroundHidden
            label="Docu title"
            placeholder="Untitled document"
            className="rounded-none border-0 border-b-2 px-0 pb-1 font-serif text-big-label text-neutral-150 disabled:cursor-text disabled:text-neutral-150"
            errorMessage={validationErrors.title?.message}
            {...register('title', {
              required: true,
            })}
            disabled={viewOnly}
          />
        )}

        <Controller
          name="text"
          control={control}
          rules={{ required: true }}
          render={() => <></>}
        />
        <Editor
          initialContentMarkdown={selectedText}
          elKey={selectedActivityTextId}
          readOnly={viewOnly}
          placeholder="Start writing your text document..."
          className="text-neutral-150"
          contentClassName="h-[calc(100vh-400px)] overflow-y-auto"
          innerRef={editorRef}
          onChange={(editorState) => {
            editorState.read(() => {
              // Keep form field in sync with editor state for validation
              setValue('text', $convertToMarkdownString(TRANSFORMERS), {
                shouldDirty: true,
              });
            });
          }}
          onError={(error: Error) => {
            console.error(error);
          }}
        />
      </div>
      {/* Note: This modal must be nested within the main modal for stacking
        logic to function properly */}
      <UnsavedChangesModal
        isModalOpen={isUnsavedChangesModalOpen}
        setClosed={() => setIsUnsavedChangesModalOpen(false)}
        onConfirm={() => {
          setIsUnsavedChangesModalOpen(false);
          closeAndResetAddOrEditTextModal();
          navTransition?.retry();
        }}
      />
    </ModalDialog>
  );
};

export default AddOrEditTextModal;
