import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';

import {
  ArrowNarrowLeftIcon,
  BookOpenIcon,
  ChatIcon,
  ChevronDownIcon,
  SparklesIcon,
  XIcon,
} from '@heroicons/react/outline';

import debounce from 'lodash.debounce';
import { ApolloError } from '@apollo/client';
import { Combobox, Transition } from '@headlessui/react';
import classNames from 'classnames';
import _ from 'lodash';
import { marked } from 'marked';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  ActivityPdf,
  ActivityType,
  ActivityUseType,
  FollowUpTemplateDataFragment,
  FollowUpTemplateDataFragmentDoc,
  FollowUpTemplatesDocument,
  PatientDataPermission,
  PatientLifecycleStatusV2,
  PatientNameDataFragment,
  ProgramTagDataFragment,
  SignatureUser,
  useAddVoiceNoteMutation,
  useDeleteFollowUpTemplateMutation,
  useDuplicateFollowUpTemplateMutation,
  useEditActivityFileSettingsMutation,
  useFollowUpTemplateQuery,
  useFollowUpTemplatesQuery,
  useMeProviderProgramTagsWithCountQuery,
  usePatientNamesForRecipientsLazyQuery,
  usePatientQuery,
  usePatientsV2NamesQuery,
  useProgramNotesQuery,
  useRenameFollowUpTemplateMutation,
  useSaveFollowUpTemplateMutation,
  useSendFollowUpToManyMutation,
} from '../../../generated/graphql';

import Spinner from '../../svgs/Spinner';

import { useAuth } from '../../../contexts/AuthContext';

import { resizeTextArea } from '../../lib/form';
import { getTimeStamp } from '../../lib/time';

import useFixPage from '../../hooks/useFixPage';
import usePaintScreen from '../../hooks/usePaintScreen';

import Button from '../../components/Button';
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal';
import '../../components/Editor/index.css';
import IconButton from '../../components/IconButton';
import FollowUpIntroModal from '../../components/Modals/FollowUpIntroModal';
import { sortPatientsByAlphabeticalAscending } from '../../components/PatientItem';
import TextAreaGroup from '../../components/TextAreaGroup';

import {
  AnalyticsPage,
  CommonAnalyticsEvent,
  trackProviderEvent,
} from '../../../lib/analytics';
import {
  HomecomingError,
  isAbortError,
  matchHomecomingError,
} from '../../../lib/errors';
import AudioRecorderPlayer, {
  AudioRecorderPlayerRef,
} from '../../components/AudioRecorderPlayer';
import Avatar from '../../components/Avatar';
import AddAudioModal from '../../components/Modals/AddAudioModal';
import AddImageModal from '../../components/Modals/AddImageModal';
import AddLinkModal from '../../components/Modals/AddLinkModal';
import AddOrEditTextModal from '../../components/Modals/AddOrEditTextModal';
import AddResourceModal, {
  AddResourceModalRef,
} from '../../components/Modals/AddResourceModal';
import SectionLabel from '../../components/SectionLabel';
import PillButton from '../../components/PillButton';
import {
  FollowUpDecoratorKeys,
  FollowUpForm,
  SavedActivityInputPreview,
  defaultActivitySchema,
} from '../../lib/followUp';
import ActivityCreationCard from './ActivityCreationCard';
import FollowUpTitle from './FollowUpTitle';
import {
  MAX_SUBJECT_LENGTH,
  convertPatientToPatientNameData,
  getProgramAssessmentIdsNeedingSignature,
  savedActivityInputPreviewToActivityTemplateInput,
} from './helpers';
import { HAS_DISMISSED_CHAT_NUDGE } from '../../lib/storage';
import Tag from '../../svgs/Tag';
import { pluralize } from '../../lib/copy';
import { formatProviderActivityForFollowUp } from '../../lib/providerActivity';
import CircleCheck from '../../svgs/CircleCheck';
import AddPDFModal from '../../components/Modals/AddPDFModal';
import FileSettingsModal from '../../components/Modals/FileSettingsModal';
import { FILE_ACTIVITY_TYPES } from '../../lib/activity';
import SendToTagConfirmationModal from './SendToTagConfirmationModal';
import ProgramTagPill from './ProgramTagPill';
import useLocalStorageValue from '../../hooks/useLocalStorageValue';
import ReviewSignerDetailsModal, {
  SavedActivityInputPreviewWithActivityIndex,
} from '../../components/Modals/MultiSignatureModals/ReviewSignerDetailsModal';
import { customToast } from '../../components/ToastAlert/customToast';
import PageContainer from '../../components/Containers/PageContainer';
import FollowUpDrawer from './FollowUpDrawer';
import SaveTemplateButton from './SaveTemplateButton';
import NameFollowUpTemplateModal from './NameFollowUpTemplateModal';
import InputGroup from '../../components/InputGroup';
import toast from 'react-hot-toast';
import ToastAlert from '../../components/ToastAlert';
import PatientPill from './PatientPill';
import AddANewResourceMenu from '../../components/AddANewResourceMenu';

const FORM_DEBOUNCE_MS = 400;
const FORM_CHANGES_STATE_DELAY_MS = 100;

const FollowUpBuilder = () => {
  useFixPage();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as {
    initialActivities?: SavedActivityInputPreview[];
    initialProgramTags?: ProgramTagDataFragment[];
  };
  const showPage = usePaintScreen();
  const { authedProviderUser, showUpgradeBanner } = useAuth();

  const programId = params.programId ?? '';
  const followUpTemplateId = params.followUpTemplateId ?? null;
  const initialActivities = locationState?.initialActivities;
  const initialProgramTags = locationState?.initialProgramTags;

  const [submitting, setSubmitting] = useState(false);

  const [isAddPDFModalOpen, setIsAddPDFModalOpen] = useState(false);
  const [isAddLinkModalOpen, setIsAddLinkModalOpen] = useState(false);
  const [isAddAudioModalOpen, setIsAddAudioModalOpen] = useState(false);
  const [isAddImageModalOpen, setIsAddImageModalOpen] = useState(false);
  const [isAddOrEditTextModalOpen, setIsAddOrEditTextModalOpen] =
    useState(false);

  const addResourceModalRef = useRef<AddResourceModalRef | null>(null);
  const [isAddResourceModalOpen, setIsAddResourceModalOpen] = useState(false);
  const [isFileSettingsModalOpen, setIsFileSettingsModalOpen] = useState(false);

  const [isFollowUpIntroModalOpen, setIsFollowUpIntroModalOpen] =
    useState(false);
  const [
    isSendToTagConfirmationModalOpen,
    setIsSendToTagConfirmationModalOpen,
  ] = useState(false);
  const [isNameFollowUpTemplateModalOpen, setIsNameFollowUpTemplateModalOpen] =
    useState(false);
  const [
    isRenameFollowUpTemplateModalOpen,
    setIsRenameFollowUpTemplateModalOpen,
  ] = useState(false);

  const audioRecorderPlayerRef = useRef<AudioRecorderPlayerRef | null>(null);
  const [draftedVoiceNoteMediaUrl, setDraftedVoiceNoteMediaUrl] = useState<
    string | undefined
  >();

  // This used to be mutable, but we walked that back, so this is permanently false
  const isIntake = false;

  const abortAddVoiceNote = useRef(new AbortController());
  const noteFieldRef = useRef<HTMLTextAreaElement | null>(null);

  const hasSentFollowUps = Boolean(authedProviderUser?.hasSentFollowUps);

  const { loading: followUpTemplateLoading } = useFollowUpTemplateQuery({
    variables: {
      followUpTemplateId,
    },
    skip: !followUpTemplateId,
    onCompleted: (data) => {
      if (data?.followUpTemplate) {
        handleApplyFollowUpTemplate(data.followUpTemplate);
      }
    },
    onError: (error) => {
      customToast.error('Unable to fetch mail template.');
    },
  });

  const {
    data: followUpTemplatesQueryData,
    refetch: refetchFollowUpTemplates,
  } = useFollowUpTemplatesQuery({
    variables: {
      input: {
        isDraft: false,
      },
    },
    fetchPolicy: 'network-only',
  });

  const { data: followUpDraftsQueryData, refetch: refetchFollowUpDrafts } =
    useFollowUpTemplatesQuery({
      variables: {
        input: {
          isDraft: true,
        },
      },
      fetchPolicy: 'network-only',
    });

  // Sorted alphabetically. This is done on top of the back-end to re-sort on cache updates.
  const followUpTemplates = (
    followUpTemplatesQueryData?.followUpTemplates ?? []
  ).sort((a, b) => a.name.localeCompare(b.name));

  // Sorted by most recently updated. This is done on top of the back-end to re-sort on cache updates.
  const followUpDrafts = (
    followUpDraftsQueryData?.followUpTemplates ?? []
  ).sort(
    (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
  );

  const { data: meProviderProgramTagsData } =
    useMeProviderProgramTagsWithCountQuery();

  const providerProgramTags =
    meProviderProgramTagsData?.meProvider?.provider?.programTags ?? [];

  const [hasDismissedChatNudge, setHasDismissedChatNudge] =
    useLocalStorageValue<boolean>(
      HAS_DISMISSED_CHAT_NUDGE,
      authedProviderUser.email,
    );

  const showChatNudge = !hasDismissedChatNudge;

  const { data: patientsData } = usePatientsV2NamesQuery({
    variables: {
      patientLifecycleStatuses: [
        PatientLifecycleStatusV2.Invited,
        PatientLifecycleStatusV2.Active,
      ],
      assignedPatients:
        authedProviderUser.patientDataPermission ===
        PatientDataPermission.Assigned,
    },
  });

  const patientsList = sortPatientsByAlphabeticalAscending(
    patientsData?.patientNames?.filter((patient) =>
      // If doesn't have premium access, can't send follow-ups to existing patients, but can send to test patients
      // so filter out real patients here.
      authedProviderUser?.hasPremiumAccess ? true : patient.isTestProgram,
    ),
  );

  const [
    patientNamesForRecipientsQuery,
    { loading: patientNamesForRecipientsQueryLoading },
  ] = usePatientNamesForRecipientsLazyQuery();

  const [addVoiceNoteMutation, { loading: addVoiceNoteLoading }] =
    useAddVoiceNoteMutation();

  const [sendFollowUpToManyMutation, { loading: sendingFollowUp }] =
    useSendFollowUpToManyMutation();

  const [
    saveFollowUpTemplateMutation,
    { loading: saveFollowUpTemplateMutationLoading },
  ] = useSaveFollowUpTemplateMutation();
  const [renameFollowUpTemplateMutation] = useRenameFollowUpTemplateMutation();
  const [duplicateFollowUpTemplateMutation] =
    useDuplicateFollowUpTemplateMutation();
  const [deleteFollowUpTemplateMutation] = useDeleteFollowUpTemplateMutation();

  const todayFormatted = getTimeStamp(
    new Date().toISOString(),
    false,
    false,
    false,
    true,
  );

  const {
    control,
    watch,
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { isDirty, errors },
    reset: resetForm,
  } = useForm<FollowUpForm>({
    mode: 'onSubmit',
    defaultValues: {
      subject: '',
      note: '',
      providerVoiceNoteId: null,
      activities: initialActivities ?? [],
      patientsOrTags: initialProgramTags ?? [],
    },
  });

  const watchSubject = watch('subject');
  const watchPatientsOrTags = watch('patientsOrTags');
  const onlyPatientOrTag =
    watchPatientsOrTags.length === 1 && watchPatientsOrTags?.[0];
  const onlyPatient =
    onlyPatientOrTag?.__typename === 'PatientName' && onlyPatientOrTag;

  const { data: patientData, refetch: refetchPatient } = usePatientQuery({
    variables: {
      programId: onlyPatient?.programInstanceId || programId,
    },
    skip: !onlyPatient?.programInstanceId && !programId,
    onError: () => {
      customToast.error('Unable to fetch client.');
    },
  });

  useEffect(() => {
    if (patientData?.patient && !watchPatientsOrTags.length) {
      const patientNameData = convertPatientToPatientNameData(
        patientData.patient,
      );
      setValue('patientsOrTags', [patientNameData]);
    }
  }, [patientData]);

  const hasMultipleRecipients =
    watchPatientsOrTags.length > 1 ||
    watchPatientsOrTags?.[0]?.__typename === 'ProgramTag';

  const watchActivities = watch('activities');

  const [isReviewSignerDetailsModalOpen, setIsReviewSignerDetailsModalOpen] =
    useState(false);
  const [
    unassignedMultiSignatureActivities,
    setUnassignedMultiSignatureActivities,
  ] = useState<SavedActivityInputPreviewWithActivityIndex[]>();

  // Helper function to check if a single activity requires the provider's signature first
  const requiresProviderSignatureFirst = (activity) =>
    activity?.sendFollowUpActivityInputRaw?.programAssessmentSignatureUsers?.some(
      ({ providerUserId }) => providerUserId === authedProviderUser?.id,
    ) &&
    activity?.multiSignatureAssessmentConfig?.assessmentSignatureUsers?.some(
      ({ signatureUserType, orderIndex }) =>
        signatureUserType === SignatureUser.SendingProviderUser &&
        orderIndex === 0,
    );

  // Check if any activity requires the provider's signature first
  const requiresImmediateSignature = watchActivities.some(
    requiresProviderSignatureFirst,
  );

  const isMultiSignatureActivitiesValid = () => {
    const multiSignatureActivities = watchActivities
      .map((activity, activityIndex) => ({ ...activity, activityIndex }))
      .filter((activity) => activity.multiSignatureAssessmentConfig);

    if (multiSignatureActivities.length === 0) {
      return true;
    }

    setSubmitting(false);

    if (!onlyPatient) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          level="warning"
          message="To send multi-signature forms, please select 1 client as the recipient, and try again."
        />
      ));
      return false;
    }

    const unassignedMultiSignatureActivities = multiSignatureActivities.filter(
      (activity) =>
        !activity.sendFollowUpActivityInputRaw?.programAssessmentSignatureUsers,
    );

    if (unassignedMultiSignatureActivities.length > 0) {
      setUnassignedMultiSignatureActivities(unassignedMultiSignatureActivities);
      setIsReviewSignerDetailsModalOpen(true);
      return false;
    }

    return true;
  };

  const watchNote = watch('note');
  const { ref: noteRef, ...noteRegister } = register('note', {
    required: false,
    onBlur: () => {
      setValue('note', watchNote.trim());
      resizeTextArea(noteFieldRef);
    },
    onChange: () => {
      resizeTextArea(noteFieldRef);
    },
  });

  const watchProviderVoiceNoteId = watch('providerVoiceNoteId');

  const [selectedActivityPdfId, setSelectedActivityPdfId] = useState<string>();

  const {
    fields: activities,
    remove,
    prepend,
    update,
  } = useFieldArray({
    control,
    name: 'activities',
  });

  const hasRecordedVoiceNote = watchProviderVoiceNoteId !== null;
  const hasMessage = watchNote.trim().length || hasRecordedVoiceNote;
  const hasActivities = Boolean(activities.length);

  const hasValidForm =
    watchPatientsOrTags.length &&
    (hasMessage || hasActivities) &&
    !addVoiceNoteLoading;

  const canSaveTemplate =
    (hasMessage || hasActivities) && !addVoiceNoteLoading && !submitting;

  const { data: programNotesData } = useProgramNotesQuery({
    variables: {
      programId: onlyPatient?.programInstanceId,
    },
    skip: !onlyPatient?.programInstanceId || watchPatientsOrTags?.length > 1,
    onError: (error) => {
      customToast.error('Unable to fetch notes.');
    },
  });

  // Convert markdown notes to HTML
  const programNotes = useMemo(
    () =>
      programNotesData?.programNotes
        ? programNotesData?.programNotes.map((programNote) => ({
            ...programNote,
            note: marked.parse(programNote.note),
          }))
        : [],
    [programNotesData?.programNotes],
  );

  const handleSaveFollowUpTemplate = async (
    name: string,
    followUpTemplateId?: string,
  ) => {
    try {
      const {
        subject,
        note: providerNote,
        providerVoiceNoteId,
        activities,
      } = getValues();
      const { data } = await saveFollowUpTemplateMutation({
        variables: {
          input: {
            id: followUpTemplateId,
            name,
            subject,
            isDraft: false,
            providerNote,
            providerVoiceNoteId,
            activityTemplates: activities.map((activity) =>
              savedActivityInputPreviewToActivityTemplateInput(activity),
            ),
          },
        },
        update: (cache, { data }) => {
          if (data?.saveFollowUpTemplate) {
            const savedFollowUpTemplate = data.saveFollowUpTemplate;

            cache.writeFragment({
              id: cache.identify(savedFollowUpTemplate),
              fragment: FollowUpTemplateDataFragmentDoc,
              fragmentName: 'followUpTemplateData',
              data: savedFollowUpTemplate,
            });

            cache.updateQuery(
              {
                query: FollowUpTemplatesDocument,
                variables: { input: { isDraft: false } },
              },
              (existingQueryData) => {
                if (!existingQueryData) return null;

                const updatedTemplates = followUpTemplateId
                  ? existingQueryData.followUpTemplates.map((template) =>
                      template.id === followUpTemplateId
                        ? savedFollowUpTemplate
                        : template,
                    )
                  : [
                      ...existingQueryData.followUpTemplates,
                      savedFollowUpTemplate,
                    ];

                return { followUpTemplates: updatedTemplates };
              },
            );
          }
        },
      });

      if (data?.saveFollowUpTemplate) {
        if (followUpTemplateId) {
          customToast.success('Mail template updated.');
        } else {
          customToast.success('New mail template saved.');
        }
      }
    } catch (err) {
      if (
        matchHomecomingError(
          err as ApolloError,
          HomecomingError.ResourceForbidden,
        )
      ) {
        // Do nothing, just throw for the caller to handle
      } else {
        console.error('Error saving follow-up template:', err);
        customToast.error('Failed to save mail template.');
      }
      throw err;
    }
  };

  const currentFollowUpTemplateDraftId = useRef<string>();
  const [currentDraftLastSavedAt, setCurrentDraftLastSavedAt] =
    useState<string>();
  const [isSavingDraft, setIsSavingDraft] = useState(false);

  // This must be memoized with useCallback so that it closes upon the latest
  // state it needs to pull from the component context.
  const handleSaveFollowUpDraft = useCallback(async () => {
    setIsSavingDraft(true);
    try {
      const followUpTemplateDraftId = currentFollowUpTemplateDraftId.current;
      const {
        patientsOrTags,
        subject,
        note: providerNote,
        providerVoiceNoteId,
        activities,
      } = getValues();
      const programIds = patientsOrTags
        .map((patientOrTag) => {
          return patientOrTag.__typename === 'PatientName'
            ? patientOrTag.programInstanceId
            : undefined;
        })
        .filter(Boolean);
      const programTagIds = patientsOrTags
        .map((patientOrTag) => {
          return patientOrTag.__typename === 'ProgramTag'
            ? patientOrTag.id
            : undefined;
        })
        .filter(Boolean);
      const { data } = await saveFollowUpTemplateMutation({
        variables: {
          input: {
            id: followUpTemplateDraftId,
            programIds,
            programTagIds,
            subject,
            isDraft: true,
            providerNote,
            providerVoiceNoteId,
            activityTemplates: activities.map((activity) =>
              savedActivityInputPreviewToActivityTemplateInput(activity),
            ),
          },
        },
        update: (cache, { data }) => {
          if (data?.saveFollowUpTemplate) {
            const savedFollowUpTemplateDraft = data.saveFollowUpTemplate;

            cache.writeFragment({
              id: cache.identify(savedFollowUpTemplateDraft),
              fragment: FollowUpTemplateDataFragmentDoc,
              fragmentName: 'followUpTemplateData',
              data: savedFollowUpTemplateDraft,
            });

            cache.updateQuery(
              {
                query: FollowUpTemplatesDocument,
                variables: { input: { isDraft: true } },
              },
              (existingQueryData) => {
                if (!existingQueryData) return null;

                const updatedTemplateDrafts = followUpTemplateDraftId
                  ? existingQueryData.followUpTemplates.map((templateDraft) =>
                      templateDraft.id === followUpTemplateDraftId
                        ? savedFollowUpTemplateDraft
                        : templateDraft,
                    )
                  : [
                      ...existingQueryData.followUpTemplates,
                      savedFollowUpTemplateDraft,
                    ];

                return { followUpTemplates: updatedTemplateDrafts };
              },
            );
          }
        },
      });
      if (data?.saveFollowUpTemplate) {
        // Draft that has just been saved should always continue to be saved on until explicitly changed.
        currentFollowUpTemplateDraftId.current = data.saveFollowUpTemplate.id;
        setCurrentDraftLastSavedAt(data.saveFollowUpTemplate.updatedAt);
      }
    } catch (err) {
      console.error('Error saving follow-up draft:', err);
    } finally {
      setIsSavingDraft(false);
    }
  }, [getValues, saveFollowUpTemplateMutation, setCurrentDraftLastSavedAt]);

  // Saving the debounce in a ref provides a single stable reference to the debounced function
  const debouncedSaveRef = useRef<ReturnType<typeof debounce>>();

  useEffect(() => {
    // And managing it in the useEffect ensures that the stable reference is created
    // and updated only when the callback (including its closed dependencies) changes,
    // and gives us to a chance to cancel the last debounce on change.
    debouncedSaveRef.current = debounce(
      handleSaveFollowUpDraft,
      FORM_DEBOUNCE_MS,
      {
        leading: false,
        trailing: true,
      },
    );

    return () => {
      debouncedSaveRef.current?.cancel();
    };
  }, [handleSaveFollowUpDraft]);

  // Finally, we provide a single stable function to call the debounced save.
  const debouncedSaveFollowUpDraft = useCallback(() => {
    debouncedSaveRef.current?.();
  }, []);

  // This is pretty hacky but necessary because the isDirty state is not updated
  // within the watch callback.
  const isDirtyRef = useRef(isDirty);
  useEffect(() => {
    isDirtyRef.current = isDirty;
  }, [isDirty]);

  // Callback form of watch (https://react-hook-form.com/api/useform/watch/)
  // Pretty hacky but necessary because the isDirty state is not up-to-date
  // within the watch callback when it fires after a "reset".
  // By using setTimeout and a ref for the isDirty state, we can ensure that the
  // debouncedSaveFollowUpDraft function is called after the isDirty state has
  // been updated (due to the timeout) and that the timeout call is able to reference the latest isDirty
  // state (due to the ref). If we used isDirty directly, the timeout would close over the stale
  // isDirty state, defeating the purpose of the delay.
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setTimeout(() => {
        if (isDirtyRef.current) {
          debouncedSaveFollowUpDraft();
        }
      }, FORM_CHANGES_STATE_DELAY_MS);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleApplyFollowUpTemplate = async (
    followUpTemplate: FollowUpTemplateDataFragment,
  ) => {
    // Drafts save recipients, so we want to replace them in that case.
    // For templates, they don't save recipients, we want to keep the current recipients in the form but still reset the form state.
    const patientsAndTags = followUpTemplate.isDraft
      ? [...followUpTemplate.patients, ...followUpTemplate.programTags]
      : getValues('patientsOrTags');

    const formattedActivities = followUpTemplate.activityTemplates.map(
      (activityTemplate) =>
        formatProviderActivityForFollowUp(
          activityTemplate.activity,
          1,
          activityTemplate.providerNote,
        ),
    );

    // Audio recorder holds it's own interal blob state post-recording, so we need to reset it
    // here from outside the component along with the providerVoiceNoteId and draftedVoiceNoteMediaUrl
    // in order to fully
    await audioRecorderPlayerRef.current?.resetRecorder();

    resetForm({
      patientsOrTags: patientsAndTags,
      subject: followUpTemplate.subject,
      note: followUpTemplate.providerNote,
      providerVoiceNoteId: followUpTemplate.providerVoiceNote?.id ?? null,
      activities: formattedActivities,
    });
    setDraftedVoiceNoteMediaUrl(followUpTemplate.providerVoiceNote?.media.url);

    resizeTextArea(noteFieldRef);

    if (followUpTemplate.isDraft) {
      currentFollowUpTemplateDraftId.current = followUpTemplate.id;
      setCurrentDraftLastSavedAt(undefined);
      customToast.success('Draft applied.');
    } else {
      customToast.success('Template applied.');
    }
  };

  const [followUpTemplateToRename, setFollowUpTemplateToRename] = useState<
    FollowUpTemplateDataFragment | undefined
  >();

  const handleRenameFollowUpTemplateClick = async (
    followUpTemplate: FollowUpTemplateDataFragment,
  ) => {
    setFollowUpTemplateToRename(followUpTemplate);
    setIsRenameFollowUpTemplateModalOpen(true);
  };

  const handleRenameFollowUpTemplate = async (
    followUpTemplate: FollowUpTemplateDataFragment,
    newName: string,
  ) => {
    try {
      await renameFollowUpTemplateMutation({
        variables: { input: { id: followUpTemplate.id, name: newName } },
        update: (cache, { data }) => {
          cache.modify({
            id: cache.identify(data.renameFollowUpTemplate),
            fields: {
              name: () => data.renameFollowUpTemplate.name,
            },
          });
        },
      });
      customToast.success('Template renamed.');
    } catch (err) {
      console.error('Error renaming template:', err);
      customToast.error('Failed to rename template.');
    }
  };

  const handleDuplicateFollowUpTemplate = async (
    followUpTemplate: FollowUpTemplateDataFragment,
  ) => {
    try {
      await duplicateFollowUpTemplateMutation({
        variables: { input: { id: followUpTemplate.id } },
      });
      await refetchFollowUpTemplates();
      customToast.success('Template duplicated.');
    } catch (err) {
      console.error('Error duplicating template:', err);
      customToast.error('Failed to duplicate template.');
    }
  };

  const handleDeleteFollowUpTemplate = async (
    followUpTemplate: FollowUpTemplateDataFragment,
  ) => {
    try {
      await deleteFollowUpTemplateMutation({
        variables: { input: { id: followUpTemplate.id } },
      });
      if (followUpTemplate.isDraft) {
        await refetchFollowUpDrafts();
      } else {
        await refetchFollowUpTemplates();
      }
      customToast.success(
        `${followUpTemplate.isDraft ? 'Draft' : 'Template'} deleted.`,
      );
    } catch (err) {
      console.error('Error deleting template:', err);
      customToast.error(
        `Failed to delete ${followUpTemplate.isDraft ? 'draft' : 'template'}.`,
      );
    }
  };

  const [recipientPatientNames, setRecipientPatientNames] = useState<
    PatientNameDataFragment[]
  >([]);

  const sendFollowUpWithCheck = async () => {
    if (
      watchPatientsOrTags.some(
        (patientOrTag) => patientOrTag.__typename === 'ProgramTag',
      )
    ) {
      const { patientsOrTags } = getValues();
      const programIds = patientsOrTags
        .map((patientOrTag) => {
          return patientOrTag.__typename === 'PatientName'
            ? patientOrTag.programInstanceId
            : undefined;
        })
        .filter(Boolean);
      const programTagIds = patientsOrTags
        .map((patientOrTag) => {
          return patientOrTag.__typename === 'ProgramTag'
            ? patientOrTag.id
            : undefined;
        })
        .filter(Boolean);

      const { data } = await patientNamesForRecipientsQuery({
        variables: {
          programIds,
          programTagIds,
        },
      });

      setRecipientPatientNames(data?.patientNamesForRecipients ?? []);

      setIsSendToTagConfirmationModalOpen(true);
    } else {
      handleSubmit(onSendFollowUp, handleErrors)();
    }
  };

  const onSendFollowUp: SubmitHandler<FollowUpForm> = async () => {
    setSubmitting(true);

    const multiSignatureActivitiesValid = isMultiSignatureActivitiesValid();

    if (!multiSignatureActivitiesValid) {
      return;
    }

    try {
      const {
        patientsOrTags,
        subject,
        note: providerNote,
        providerVoiceNoteId,
        activities,
      } = getValues();
      const programIds = patientsOrTags
        .map((patientOrTag) => {
          return patientOrTag.__typename === 'PatientName'
            ? patientOrTag.programInstanceId
            : undefined;
        })
        .filter(Boolean);
      const programTagIds = patientsOrTags
        .map((patientOrTag) => {
          return patientOrTag.__typename === 'ProgramTag'
            ? patientOrTag.id
            : undefined;
        })
        .filter(Boolean);

      const { data } = await sendFollowUpToManyMutation({
        variables: {
          input: {
            programIds,
            programTagIds,
            subject,
            providerNote,
            providerVoiceNoteId,
            activities: activities.map((activity) =>
              _.omit(
                activity.sendFollowUpActivityInputRaw,
                FollowUpDecoratorKeys,
              ),
            ),
            sendEmail: true,
            sendSms: false,
            isIntake,
            ...(currentFollowUpTemplateDraftId.current && {
              sourceDraftFollowUpTemplateId:
                currentFollowUpTemplateDraftId.current,
            }),
          },
        },
      });
      if (data?.sendFollowUpToMany) {
        customToast.success('Mail sent successfully.');

        if (onlyPatient) {
          const programAssessmentIdsNeedingSignature =
            getProgramAssessmentIdsNeedingSignature(
              data?.sendFollowUpToMany?.[0],
              authedProviderUser?.id,
            );

          if (programAssessmentIdsNeedingSignature?.length > 0) {
            const [
              firstProgramAssessmentId,
              ...remainingProgramAssessmentIdsNeedingSignature
            ] = programAssessmentIdsNeedingSignature;

            navigate(`/provider-form/${firstProgramAssessmentId}`, {
              ...(remainingProgramAssessmentIdsNeedingSignature.length > 0 && {
                state: { remainingProgramAssessmentIdsNeedingSignature },
              }),
            });
          } else {
            navigate(`/clients/${onlyPatient.programInstanceId}/follow-ups`);
          }
        } else {
          navigate(`/clients/active`);
        }
      }
    } catch (err) {
      customToast.error('Something went wrong.');
    } finally {
      setSubmitting(false);
    }
  };

  const onAddVoiceNote = async (audioFile: File, audioDuration: number) => {
    try {
      const { data } = await addVoiceNoteMutation({
        variables: {
          input: {
            file: audioFile,
            duration: audioDuration,
          },
        },
        context: { fetchOptions: { signal: abortAddVoiceNote.current.signal } },
      });
      setValue('providerVoiceNoteId', data?.addVoiceNote.id ?? null);
    } catch (err) {
      if (isAbortError(err as ApolloError)) return;
      customToast.error('Something went wrong.');
    }
  };

  const onDiscardVoiceNote = async () => {
    abortAddVoiceNote.current && abortAddVoiceNote.current.abort();
    abortAddVoiceNote.current = new AbortController();
    setValue('providerVoiceNoteId', null);
    setDraftedVoiceNoteMediaUrl(undefined);
  };

  const handleErrors: SubmitErrorHandler<FollowUpForm> = (errors) => {
    console.error('Errors submitting:', errors);
  };

  useEffect(() => {
    if (showPage && !hasSentFollowUps) setIsFollowUpIntroModalOpen(true);
  }, [hasSentFollowUps]);

  const [isDeleteFollowUpDraftModalOpen, setIsDeleteFollowUpDraftModalOpen] =
    useState(false);

  const addActionActivity = () => {
    const newActionActivity = {
      ...defaultActivitySchema,
      sendFollowUpActivityInputRaw: {
        ...defaultActivitySchema.sendFollowUpActivityInputRaw,
        dateCreated: Date.now(),
      },
    };

    prepend(newActionActivity);
  };

  // Filtering the client selector by search query
  const [patientOrTagQuery, setPatientOrTagQuery] = useState('');

  const watchedProgramInstanceIds = new Set(
    watchPatientsOrTags
      .map(
        (patientOrTag) =>
          patientOrTag.__typename === 'PatientName' &&
          patientOrTag.programInstanceId,
      )
      .filter(Boolean),
  );

  const watchedProgramTagIds = new Set(
    watchPatientsOrTags
      .map(
        (patientOrTag) =>
          patientOrTag.__typename === 'ProgramTag' && patientOrTag.id,
      )
      .filter(Boolean),
  );

  const combinedPatientsAndTagsList = [...providerProgramTags, ...patientsList];

  const filteredPatientsOrTags = combinedPatientsAndTagsList
    .filter((patientOrTag) => {
      if (patientOrTag.__typename === 'PatientName') {
        return !watchedProgramInstanceIds.has(patientOrTag.programInstanceId);
      } else if (patientOrTag.__typename === 'ProgramTag') {
        return !watchedProgramTagIds.has(patientOrTag.id);
      }
      return true;
    })
    .filter((patientOrTag) => {
      if (patientOrTagQuery) {
        if (patientOrTag.__typename === 'PatientName') {
          return (
            patientOrTag?.firstName
              ?.toLowerCase()
              .includes(patientOrTagQuery.toLowerCase()) ||
            patientOrTag?.lastName
              ?.toLowerCase()
              .includes(patientOrTagQuery.toLowerCase())
          );
        } else if (patientOrTag.__typename === 'ProgramTag') {
          return patientOrTag?.name
            ?.toLowerCase()
            .includes(patientOrTagQuery.toLowerCase());
        }
      }
      return true;
    });

  const [editActivityFileSettingsMutation] =
    useEditActivityFileSettingsMutation();

  const handleUpdateActivityUseType = async (
    activityId: string,
    activityType: ActivityType,
    activityUseType: ActivityUseType,
    activityIndex: number,
  ) => {
    try {
      const editActivityFileSettingsInput = {
        activityId,
        activityType,
        activityUseType,
        ...(FILE_ACTIVITY_TYPES.includes(activityType) && {
          activityType: ActivityType.Pdf,
        }),
      };

      const { data } = await editActivityFileSettingsMutation({
        variables: { input: editActivityFileSettingsInput },
      });

      const updatedActivity = data?.editActivityFileSettings;

      if (updatedActivity) {
        customToast.success('Successfully changed file settings');
        update(
          activityIndex,
          formatProviderActivityForFollowUp(updatedActivity),
        );
      }
    } catch (err) {
      customToast.error((err as Error).message);
    }
  };

  return (
    <>
      <PageContainer
        noPadding
        containerClassName={classNames(
          'relative',
          showUpgradeBanner
            ? 'h-[calc(100vh-var(--top-nav-height)-var(--upgrade-banner-height))]'
            : 'h-[calc(100vh-var(--top-nav-height))]',
        )}
      >
        <div className="fixed z-40 flex h-14 w-full flex-row items-center justify-between border-b border-neutral-50 bg-white py-3 px-20">
          <div className="flex min-w-[400px] flex-row items-center justify-start">
            {window.history.length > 1 && (
              <IconButton
                IconComponent={ArrowNarrowLeftIcon}
                iconClassName="text-green-100 w-6"
                aria-label="Back"
                onClick={() => navigate(-1)}
              />
            )}
          </div>
          <FollowUpTitle
            isSaving={isSavingDraft}
            lastSavedAt={currentDraftLastSavedAt}
          />
          <div className="flex min-w-[333px] flex-row items-center justify-end">
            {Boolean(sendingFollowUp) && (
              <Spinner className="mr-3 h-5 w-5 text-neutral-125" />
            )}
            <SaveTemplateButton
              disabled={!canSaveTemplate || saveFollowUpTemplateMutationLoading}
              existingTemplates={followUpTemplates}
              onClickSaveAsNewTemplate={async () =>
                setIsNameFollowUpTemplateModalOpen(true)
              }
              onClickUpdateExistingTemplate={async (
                followUpTemplate: FollowUpTemplateDataFragment,
              ) =>
                await handleSaveFollowUpTemplate(
                  followUpTemplate.name,
                  followUpTemplate.id,
                )
              }
            />
            <Button
              title={requiresImmediateSignature ? 'Complete & send' : 'Send'}
              size="small"
              className="px-6 py-1.5"
              onClick={sendFollowUpWithCheck}
              disabled={
                !hasValidForm ||
                submitting ||
                patientNamesForRecipientsQueryLoading ||
                isSavingDraft
              }
            />
          </div>
        </div>
        <div className="flex h-full flex-row pt-14">
          <FollowUpDrawer
            programNotes={programNotes}
            onlyPatient={Boolean(onlyPatient)}
            followUpTemplates={followUpTemplates}
            followUpDrafts={followUpDrafts}
            onApplyFollowUpTemplate={handleApplyFollowUpTemplate}
            onRenameFollowUpTemplate={handleRenameFollowUpTemplateClick}
            onDuplicateFollowUpTemplate={handleDuplicateFollowUpTemplate}
            onDeleteFollowUpTemplate={handleDeleteFollowUpTemplate}
          />
          <div className="flex h-auto flex-1 flex-col items-center overflow-y-auto pb-8">
            <div className="mt-16 w-9/12 max-w-[720px] pb-16">
              {showChatNudge && onlyPatient && (
                <div className="mb-6 flex flex-row">
                  <ChatIcon className="mr-2 h-4 w-4 text-neutral-125" />
                  <div className="text-caption text-neutral-150">
                    New! Want to send a quick text?{' '}
                    <span
                      className="cursor-pointer font-bold text-green-100 hover:underline"
                      onClick={() => {
                        trackProviderEvent(CommonAnalyticsEvent.ButtonClicked, {
                          originPage: AnalyticsPage.FollowUpBuilder,
                          buttonName: 'Try chat',
                        });
                        navigate(
                          `/clients/${onlyPatient?.programInstanceId}/messages`,
                        );
                      }}
                    >
                      Try Chat
                    </span>{' '}
                    for the small stuff.
                  </div>
                  <XIcon
                    className="relative top-[2px] ml-2 h-4 w-4 cursor-pointer text-neutral-100 hover:text-neutral-110"
                    onClick={(e) => {
                      e.preventDefault();
                      setHasDismissedChatNudge(true);
                    }}
                  />
                </div>
              )}

              {/* Recipients section */}
              <SectionLabel title="Recipients" />
              <Controller
                name="patientsOrTags"
                control={control}
                defaultValue={undefined}
                rules={{
                  required: {
                    value: true,
                    message: 'Choose your recipients or tags',
                  },
                }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <>
                      {Boolean(watchPatientsOrTags.length) && (
                        <>
                          <ul className="mb-4 flex flex-row flex-wrap gap-2">
                            {watchPatientsOrTags.map((patientOrTag) => {
                              if (patientOrTag.__typename === 'PatientName') {
                                const patient = patientOrTag;
                                return (
                                  <PatientPill
                                    key={patient.programInstanceId}
                                    patient={patient}
                                    onDelete={(patient) => {
                                      onChange(
                                        _.without(watchPatientsOrTags, patient),
                                      );
                                    }}
                                  />
                                );
                              } else if (
                                patientOrTag.__typename === 'ProgramTag'
                              ) {
                                const programTag = patientOrTag;
                                return (
                                  <ProgramTagPill
                                    key={programTag.id}
                                    programTag={programTag}
                                    onDelete={(programTag) =>
                                      onChange(
                                        _.without(
                                          watchPatientsOrTags,
                                          programTag,
                                        ),
                                      )
                                    }
                                  />
                                );
                              } else {
                                return null;
                              }
                            })}
                          </ul>
                          {hasMultipleRecipients && (
                            <div className="mb-4 flex flex-row items-center gap-x-2 text-neutral-125">
                              <SparklesIcon className="h-6 min-w-[24px]" />
                              <div className="text-caption text-neutral-200">
                                Your follow up will be delivered to these
                                recipients separately.
                              </div>
                            </div>
                          )}
                        </>
                      )}

                      <Combobox
                        as="div"
                        className="relative mb-8 w-full text-body"
                        value={value}
                        onChange={(patientsOrTags) => {
                          setPatientOrTagQuery('');
                          onChange(patientsOrTags);
                        }}
                        immediate
                        multiple
                      >
                        <Combobox.Input
                          className={classNames(
                            'relative flex w-full cursor-default flex-row items-center justify-start text-body placeholder:text-body',
                            'max-h-14 rounded-md bg-neutral-50 p-4 text-left text-green-150 shadow-sm sm:text-caption',
                            'border-0 placeholder:text-neutral-125/75 focus:border-green-50 focus:outline-none focus:ring-2 focus:ring-green-50',
                          )}
                          onChange={(event) =>
                            setPatientOrTagQuery(event.target.value)
                          }
                          placeholder="Search recipients or tags"
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronDownIcon
                            className="h-5 w-5 text-neutral-125"
                            aria-hidden="true"
                          />
                        </Combobox.Button>
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                          afterLeave={() => setPatientOrTagQuery('')}
                        >
                          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full divide-y divide-neutral-75 overflow-auto rounded-lg border border-neutral-75  bg-white py-1 text-caption shadow-lg focus:outline-none focus:ring-0">
                            {filteredPatientsOrTags.map((patientOrTag) => {
                              if (patientOrTag.__typename === 'PatientName') {
                                return (
                                  <Combobox.Option
                                    key={patientOrTag.programInstanceId}
                                    value={patientOrTag}
                                  >
                                    {({ active, selected }) => {
                                      const patientName = `${patientOrTag?.firstName} ${patientOrTag?.lastName}`;
                                      return (
                                        <div
                                          className={classNames(
                                            'relative flex cursor-pointer select-none flex-row items-center truncate py-3 px-5 text-green-150 hover:bg-neutral-25',
                                            {
                                              'bg-neutral-25':
                                                selected || active,
                                            },
                                          )}
                                        >
                                          <Avatar
                                            size="small"
                                            name={patientName}
                                            imageUrl={
                                              patientOrTag?.profileImageMedia
                                                ?.url
                                            }
                                            className="mr-3"
                                          />
                                          {patientName}
                                        </div>
                                      );
                                    }}
                                  </Combobox.Option>
                                );
                              } else if (
                                patientOrTag.__typename === 'ProgramTag'
                              ) {
                                const disabled = !Boolean(
                                  patientOrTag.programCount,
                                );
                                return (
                                  <Combobox.Option
                                    key={`tagOption_${patientOrTag.id}`}
                                    value={patientOrTag}
                                    disabled={disabled}
                                  >
                                    {({ active, selected }) => {
                                      return (
                                        <div
                                          className={classNames(
                                            'relative flex select-none flex-row truncate py-3 px-5 text-green-150 hover:bg-neutral-25',
                                            {
                                              'bg-neutral-25':
                                                selected || active,
                                            },
                                            disabled
                                              ? 'cursor-not-allowed opacity-50'
                                              : 'cursor-pointer',
                                          )}
                                        >
                                          <div className="flex w-full items-center space-x-3">
                                            <Tag className="h-6 min-w-[24px]" />
                                            <div className="flex-grow truncate text-caption">
                                              {patientOrTag.name}{' '}
                                              {`(${pluralize(
                                                patientOrTag.programCount,
                                                'client',
                                                'clients',
                                              )})`}
                                            </div>
                                            <div className="truncate text-small-caption text-neutral-100">
                                              Mail will deliver separately.
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }}
                                  </Combobox.Option>
                                );
                              }
                              return null;
                            })}
                          </Combobox.Options>
                        </Transition>
                      </Combobox>
                    </>
                  );
                }}
              />

              {/* Subject section */}
              <div className="mb-4">
                <SectionLabel title="Subject" />
                <InputGroup
                  inputSize="small"
                  placeholder="Subject"
                  label="Subject"
                  labelHidden
                  {...register('subject', {
                    maxLength: {
                      value: MAX_SUBJECT_LENGTH,
                      message: `Subject must be less than ${MAX_SUBJECT_LENGTH} characters`,
                    },
                  })}
                  errorMessage={errors.subject?.message}
                  maxLength={MAX_SUBJECT_LENGTH}
                />
              </div>

              {/* Message section */}
              <div
                className={classNames(
                  'mb-4 flex w-full',
                  !hasRecordedVoiceNote &&
                    'flex-row items-center justify-between',
                  hasRecordedVoiceNote && 'flex-col items-start justify-start',
                )}
              >
                <div className="flex flex-row items-center justify-center text-green-150">
                  <div className="text-body font-medium">Message</div>
                </div>
                <AudioRecorderPlayer
                  recordingEnabled={Boolean(watchPatientsOrTags.length)}
                  isUploading={addVoiceNoteLoading}
                  audioMediaUrl={draftedVoiceNoteMediaUrl}
                  hasUploaded={hasRecordedVoiceNote}
                  onAdd={onAddVoiceNote}
                  onDiscard={onDiscardVoiceNote}
                  filePrefix="voicenote"
                  controlsClassName={'!flex-row-reverse h-[36px]'}
                  ref={audioRecorderPlayerRef}
                />
              </div>
              <TextAreaGroup
                placeholder={`Hi ${onlyPatient?.firstName ?? 'there'}, to ${
                  isIntake ? 'get started...' : 'follow up on our session...'
                }`}
                label="note"
                value={watchNote}
                rows={3}
                labelHidden
                inputSize="small"
                className="focused:border-green-50 mb-8 h-auto"
                containerClassName="w-full"
                errorMessage={errors.note?.message}
                onFocus={() => resizeTextArea(noteFieldRef)}
                {...noteRegister}
                ref={(event) => {
                  noteRef(event);
                  noteFieldRef.current = event;
                }}
              />
              <SectionLabel title="Activities and Resources" />
              <div className="mb-6 mt-4 flex w-full flex-row items-center justify-between">
                <PillButton
                  IconComponent={BookOpenIcon}
                  title="Choose from library"
                  onClick={() => {
                    setIsAddResourceModalOpen(true);
                  }}
                />
                <PillButton
                  IconComponent={CircleCheck}
                  containerClassName="mx-3"
                  title="Suggest an activity"
                  onClick={addActionActivity}
                />
                <AddANewResourceMenu
                  onClickAddLink={() => setIsAddLinkModalOpen(true)}
                  onClickAddImage={() => setIsAddImageModalOpen(true)}
                  onClickAddPDF={() => setIsAddPDFModalOpen(true)}
                  onClickAddAudio={() => setIsAddAudioModalOpen(true)}
                  onClickAddText={() => setIsAddOrEditTextModalOpen(true)}
                />
              </div>

              {/* Activities list */}
              {activities.map((activity, activityIndex) => {
                return (
                  <ActivityCreationCard
                    activity={activity}
                    {...activity.activityPreviewCardProps}
                    key={activity.id}
                    activityIndex={activityIndex}
                    register={register}
                    watch={watch}
                    setValue={setValue}
                    errors={errors}
                    activitiesLength={activities.length}
                    onClickCloseX={() => {
                      remove(activityIndex);
                    }}
                    onClickModifyFileSettings={(activityPdfId: string) => {
                      setSelectedActivityPdfId(activityPdfId);
                      setIsFileSettingsModalOpen(true);
                    }}
                    handleUpdateActivityUseType={handleUpdateActivityUseType}
                    requiresSendingProviderSignatureFirst={requiresProviderSignatureFirst(
                      activity,
                    )}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </PageContainer>
      <AddLinkModal
        isModalOpen={isAddLinkModalOpen}
        setClosed={() => setIsAddLinkModalOpen(false)}
        followUpMode
        prependToFollowUp={prepend}
      />
      <AddPDFModal
        isModalOpen={isAddPDFModalOpen}
        setClosed={() => setIsAddPDFModalOpen(false)}
        followUpMode
        prependToFollowUp={prepend}
      />
      <AddImageModal
        isModalOpen={isAddImageModalOpen}
        setClosed={() => setIsAddImageModalOpen(false)}
        followUpMode
        prependToFollowUp={prepend}
      />
      <AddAudioModal
        isModalOpen={isAddAudioModalOpen}
        setClosed={() => setIsAddAudioModalOpen(false)}
        followUpMode
        prependToFollowUp={prepend}
      />
      <AddOrEditTextModal
        isModalOpen={isAddOrEditTextModalOpen}
        setClosed={() => setIsAddOrEditTextModalOpen(false)}
        followUpMode
        prependToFollowUp={prepend}
      />
      <AddResourceModal
        isModalOpen={isAddResourceModalOpen}
        setClosed={() => setIsAddResourceModalOpen(false)}
        prependToFollowUp={prepend}
        ref={addResourceModalRef}
      />
      <FileSettingsModal
        followUpMode
        isModalOpen={isFileSettingsModalOpen}
        setClosed={() => setIsFileSettingsModalOpen(false)}
        activityPdfId={selectedActivityPdfId}
        onUpdated={(updatedActivityPdf: ActivityPdf) => {
          // Replace the form activity in the list with the updated provider activity from the server
          const updatedActivityIndex = activities.findIndex(
            (activity) =>
              activity.sendFollowUpActivityInputRaw.activityPDFId ===
              updatedActivityPdf.id,
          );
          if (updatedActivityIndex !== -1) {
            update(
              updatedActivityIndex,
              formatProviderActivityForFollowUp(updatedActivityPdf),
            );
          }
          // Library internal to the AddResourceModal must be refreshed since we've made a change to the file
          addResourceModalRef.current?.refetchProviderActivities();
        }}
      />
      <ReviewSignerDetailsModal
        setClosed={() => setIsReviewSignerDetailsModalOpen(false)}
        isOpen={isReviewSignerDetailsModalOpen}
        sendingProviderUser={authedProviderUser}
        multiSignatureActivities={unassignedMultiSignatureActivities}
        patient={patientData?.patient}
        update={update}
        refetchPatient={refetchPatient}
      />
      <ConfirmDeleteModal
        title="Would you like to delete this mail draft?"
        actionButtonTitle="Delete mail draft"
        isOpen={isDeleteFollowUpDraftModalOpen}
        fetching={submitting}
        setClosed={() => setIsDeleteFollowUpDraftModalOpen(false)}
        performDelete={async () => void 0}
      />
      <FollowUpIntroModal
        isModalOpen={isFollowUpIntroModalOpen}
        setClosed={() => setIsFollowUpIntroModalOpen(false)}
        todayFormatted={todayFormatted}
      />
      <SendToTagConfirmationModal
        isModalOpen={isSendToTagConfirmationModalOpen}
        setClosed={() => setIsSendToTagConfirmationModalOpen(false)}
        onConfirm={() => {
          setIsSendToTagConfirmationModalOpen(false);
          handleSubmit(onSendFollowUp, handleErrors)();
        }}
        recipientPatientNames={recipientPatientNames}
      />
      <NameFollowUpTemplateModal
        isOpen={isNameFollowUpTemplateModalOpen}
        setClosed={() => setIsNameFollowUpTemplateModalOpen(false)}
        followUpTemplateName={watchSubject}
        handleSaveFollowUpTemplate={handleSaveFollowUpTemplate}
      />
      <NameFollowUpTemplateModal
        isOpen={isRenameFollowUpTemplateModalOpen}
        setClosed={() => setIsRenameFollowUpTemplateModalOpen(false)}
        followUpTemplateName={followUpTemplateToRename?.name}
        handleRenameFollowUpTemplate={handleRenameFollowUpTemplate}
        existingFollowUpTemplate={followUpTemplateToRename}
        handleSaveFollowUpTemplate={handleSaveFollowUpTemplate}
      />
      {Boolean(!showPage || followUpTemplateLoading) && (
        <Spinner className="mx-auto mt-24" />
      )}
    </>
  );
};

export default FollowUpBuilder;
