import { FC } from 'react';

import {
  AssessmentAnswer,
  AssessmentQuestionType,
  CompletedProgramAssessmentsDataQuery,
  MultiSignatureAssessmentQuestion,
  ProgramAssessmentDataFragment,
  MultipleChoiceScoredAssessmentAnswer,
  MultipleChoiceAssessmentAnswer,
  MultiSelectAssessmentAnswer,
  FreeTextAssessmentAnswer,
  RichTextAssessmentAnswer,
  SignatureAssessmentAnswer,
  MultiSignatureAssessmentAnswer,
  InitialsAssessmentAnswer,
} from '../../../../generated/graphql';

import { ProgramAssessmentQuestion } from '../../../lib/program-assessment';

import SlideoverSection from '../../SlideoverSection';
import QuestionItem from '../QuestionItem';
import CompletedSignature from '../../CompletedSignature';
import { findAnswerForQuestion } from './helpers';
import {
  isAssessmentAForm,
  isStatementAssessmentQuestion,
} from '../../../lib/assessments';
import { QuestionSubscale } from '../QuestionsSection';
import classNames from 'classnames';

const ResponsesSection: FC<{
  title?: string;
  questionFrame?: string;
  programAssessment?:
    | ProgramAssessmentDataFragment
    | CompletedProgramAssessmentsDataQuery['completedProgramAssessmentsByAssessment'][number];
}> = ({ title = 'Responses', questionFrame, programAssessment }) => {
  const questions = programAssessment?.assessment
    ?.questions as ProgramAssessmentQuestion[];
  const answers = programAssessment?.answers as AssessmentAnswer[];

  const isForm = isAssessmentAForm(programAssessment?.assessment);

  const noClientAnswers = answers?.length === 0;

  return (
    <SlideoverSection title={title}>
      {questionFrame && Boolean(answers?.length) && (
        <div className="mb-4 font-sans text-caption text-neutral-125">
          {questionFrame}
        </div>
      )}
      {noClientAnswers && (
        <div className="mb-6 text-caption font-normal text-neutral-110">
          Waiting for completion
        </div>
      )}
      {questions?.map((question, questionIndex) => {
        const isLastQuestion = questionIndex === questions.length - 1;
        const answer = findAnswerForQuestion(answers, question);

        if (question.questionType === AssessmentQuestionType.MultiSignature) {
          question = question as MultiSignatureAssessmentQuestion;

          return (
            <QuestionItem
              key={questionIndex}
              isForm={isForm}
              questionIndex={questionIndex}
              question={question.question}
              className={classNames(
                'w-full pt-2',
                isLastQuestion ? 'mb-0' : '',
              )}
              subscale={
                !Boolean(question?.existingSignatures?.length) &&
                QuestionSubscale.MultiSignature
              }
              additionalAnswerElem={
                <div className="mt-2">
                  {question.existingSignatures?.map(
                    (signature, signatureIndex) => (
                      <CompletedSignature
                        key={signatureIndex}
                        signatureName={signature.signatureName}
                        signatureRole={signature.signatureRole}
                        signedAt={new Date(signature.signedAt).toISOString()}
                        className={classNames(
                          'font-normal',
                          signatureIndex !== 0 ? 'mt-4' : 'mt-2',
                        )}
                        size="small"
                      />
                    ),
                  )}
                </div>
              }
            />
          );
        }

        if (answer) {
          switch (answer.questionType) {
            case AssessmentQuestionType.MultipleChoiceScored:
              const multipleChoiceScoredAnswer =
                answer as MultipleChoiceScoredAssessmentAnswer;
              return (
                <QuestionItem
                  key={questionIndex}
                  isForm={isForm}
                  questionIndex={questionIndex}
                  question={multipleChoiceScoredAnswer.question}
                  subscale={multipleChoiceScoredAnswer.subscale}
                  answer={multipleChoiceScoredAnswer.answer}
                  className={classNames(isLastQuestion && 'mb-0')}
                />
              );
            case AssessmentQuestionType.MultipleChoice:
              const multipleChoiceAnswer =
                answer as MultipleChoiceAssessmentAnswer;
              return (
                <QuestionItem
                  key={questionIndex}
                  isForm={isForm}
                  questionIndex={questionIndex}
                  question={multipleChoiceAnswer.question}
                  answer={multipleChoiceAnswer.answer}
                  className={classNames(
                    'w-full pt-2',
                    isLastQuestion ? 'mb-0' : '',
                  )}
                />
              );
            case AssessmentQuestionType.MultiSelect:
              const multiSelectAnswer = answer as MultiSelectAssessmentAnswer;
              return (
                <QuestionItem
                  key={questionIndex}
                  isForm={isForm}
                  questionIndex={questionIndex}
                  question={multiSelectAnswer.question}
                  answer={
                    multiSelectAnswer.answers.length
                      ? multiSelectAnswer.answers.join(', ')
                      : 'Nothing selected'
                  }
                  className={classNames(
                    'w-full pt-2',
                    isLastQuestion ? 'mb-0' : '',
                  )}
                />
              );
            case AssessmentQuestionType.FreeText:
              const freeTextAnswer = answer as FreeTextAssessmentAnswer;
              return (
                <QuestionItem
                  key={questionIndex}
                  isForm={isForm}
                  questionIndex={questionIndex}
                  question={freeTextAnswer.question}
                  answer={freeTextAnswer.answer}
                  className={classNames(
                    'w-full pt-2',
                    isLastQuestion ? 'mb-0' : '',
                  )}
                />
              );
            case AssessmentQuestionType.Initials:
              const initialsAnswer = answer as InitialsAssessmentAnswer;
              return (
                <QuestionItem
                  key={questionIndex}
                  isForm={isForm}
                  questionIndex={questionIndex}
                  question={initialsAnswer.question}
                  className={classNames(
                    'w-full pt-2',
                    isLastQuestion ? 'mb-0' : '',
                  )}
                  answerClassName="font-normal"
                  subscale={
                    !initialsAnswer.signatureName
                      ? QuestionSubscale.Initials
                      : undefined
                  }
                  additionalAnswerElem={
                    initialsAnswer.signatureName && (
                      <CompletedSignature
                        hideNameAndRole
                        signatureName={initialsAnswer.signatureName}
                        signedAt={initialsAnswer.signedAt}
                        size="small"
                        signatureRole="Client"
                      />
                    )
                  }
                />
              );
            case AssessmentQuestionType.Signature:
              const signatureAnswer = answer as SignatureAssessmentAnswer;
              return (
                <QuestionItem
                  key={questionIndex}
                  isForm={isForm}
                  questionIndex={questionIndex}
                  question={signatureAnswer.question}
                  className={classNames(
                    'w-full pt-2',
                    isLastQuestion ? 'mb-0' : '',
                  )}
                  answerClassName="font-normal"
                  subscale={
                    !signatureAnswer.signatureName
                      ? QuestionSubscale.Signature
                      : undefined
                  }
                  additionalAnswerElem={
                    signatureAnswer.signatureName && (
                      <CompletedSignature
                        signatureName={signatureAnswer.signatureName}
                        signedAt={signatureAnswer.signedAt}
                        size="small"
                        signatureRole="Client"
                      />
                    )
                  }
                />
              );
            case AssessmentQuestionType.MultiSignature:
              const multiSignatureAnswer =
                answer as MultiSignatureAssessmentAnswer;
              return (
                <QuestionItem
                  key={questionIndex}
                  isForm={isForm}
                  questionIndex={questionIndex}
                  question={multiSignatureAnswer.question}
                  className={classNames(
                    'w-full pt-2',
                    isLastQuestion ? 'mb-0' : '',
                  )}
                  answerClassName="font-normal"
                  additionalAnswerElem={
                    <div className="mt-3">
                      {multiSignatureAnswer.signatures.map(
                        (signature, signatureIndex) => (
                          <CompletedSignature
                            key={signatureIndex}
                            signatureName={signature.signatureName}
                            signatureRole={signature.signatureRole}
                            signedAt={signature.signedAt}
                            className="mb-[6px]"
                            size="small"
                          />
                        ),
                      )}
                    </div>
                  }
                />
              );
            case AssessmentQuestionType.RichText:
              const richTextAnswer = answer as RichTextAssessmentAnswer;
              return (
                <QuestionItem
                  key={questionIndex}
                  isForm={isForm}
                  questionIndex={questionIndex}
                  question={richTextAnswer.question}
                  answer={richTextAnswer.answer}
                  className={classNames(
                    'w-full pt-2',
                    isLastQuestion ? 'mb-0' : '',
                  )}
                  answerClassName="font-medium"
                />
              );
            default:
              return null;
          }
        } else {
          return (
            <QuestionItem
              key={questionIndex}
              isForm={isForm}
              questionIndex={questionIndex}
              question={question.question}
              subscale={
                !isStatementAssessmentQuestion(question) &&
                QuestionSubscale[question.questionType]
              }
              className={classNames('w-full pt-2', isLastQuestion && 'mb-0')}
            />
          );
        }
      })}
    </SlideoverSection>
  );
};

export default ResponsesSection;
