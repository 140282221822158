import { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import toast from 'react-hot-toast';

import { ProgramActivityDataFragment } from '../../../../generated/graphql';

import Spinner from '../../../svgs/Spinner';
import { defaultTransitionProps } from '../../../lib/animation';
import ToastAlert from '../../../components/ToastAlert';

import { usePatientProfileContext } from '..';

import IllustrationMaze from '../../../svgs/IllustrationMaze';
import ProgramActivitySlideover from '../../../components/ProgramActivitySlideover';
import ActivityTable from '../../../components/ActivityTable';
import EmptyProfileTab from '../../../components/EmptyProfileTab';
import { isProviderUserCurrentSigner } from '../../../components/ProgramActivityCard/helpers';
import { useAuth } from '../../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const Activities = () => {
  const {
    patient,
    getProgramActivities,
    programActivitiesData,
    programActivitiesError,
    programActivitiesLoading,
  } = usePatientProfileContext();

  const { authedProviderUser } = useAuth();
  const navigate = useNavigate();

  const [unmounted, setUnmounted] = useState(false);

  const [selectedProgramActivity, setSelectedProgramActivity] =
    useState<ProgramActivityDataFragment>();
  const [isProgramActivitySlideoverOpen, setIsProgramActivitySlideoverOpen] =
    useState<boolean>(false);

  useEffect(() => {
    getProgramActivities();
    setUnmounted(true);
  }, []);

  useEffect(() => {
    if (programActivitiesError) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Unable to fetch activities."
          level="error"
        />
      ));
    }
  }, [programActivitiesError]);

  const programActivities = programActivitiesData?.programActivities;

  return (
    <>
      {programActivitiesLoading && <Spinner className="mx-auto mt-8" />}
      <Transition
        show={Boolean(
          programActivities &&
            !programActivitiesError &&
            !programActivitiesLoading &&
            unmounted,
        )}
        {...defaultTransitionProps}
        className="w-full pt-4"
      >
        {Boolean(programActivities?.length) && (
          <ActivityTable
            programActivities={programActivities}
            programActivitiesLoading={programActivitiesLoading}
            onClickProgramActivity={(programActivity) => {
              const isCurrentSigner = isProviderUserCurrentSigner(
                programActivity,
                authedProviderUser?.id,
              );
              if (isCurrentSigner) {
                navigate(
                  `/provider-form/${programActivity?.programAssessment?.id}`,
                );
              } else {
                setSelectedProgramActivity(programActivity);
                setIsProgramActivitySlideoverOpen(true);
              }
            }}
          />
        )}
        {!Boolean(programActivities?.length) && (
          <EmptyProfileTab
            IconComponent={IllustrationMaze}
            iconClassName={'text-green-125'}
            titleText={`${patient?.firstName ?? ''}'s activities and resources`}
            subTitleText={`All the activities and resources you send to ${patient?.firstName} through a
              follow-up will show up here.`}
          />
        )}
      </Transition>

      <ProgramActivitySlideover
        isOpen={isProgramActivitySlideoverOpen}
        onClose={() => setIsProgramActivitySlideoverOpen(false)}
        programActivity={selectedProgramActivity}
      />
    </>
  );
};

export default Activities;
